import React from "react";
import GetStarted from '../Integrations/FormBlock/getStarted'
import FourthBlock from './AllIntegrationBlock/ColdStart'
// import FifthBlock from './FifthBlock/index'
import SixthBlock from './SixthBlock/index'
import AllIntegrationPage from './AllIntegrationBlock/index'
import styles from '../PersonalIntegrations/FirstBlock/index.module.scss'
import button from '../buttons.module.scss'
import EmptyBlock from '../Header/emptyBlock'


import icon1 from '../img/icons/integPageIcons/icon_activity.svg'
import icon2 from '../img/icons/integPageIcons/Icon_screenshots.svg'
import icon3 from '../img/icons/integPageIcons/Icon_online.svg'
import icon4 from '../img/icons/integPageIcons/Icon_tracking.svg'
import icon5 from '../img/icons/integPageIcons/Icon_advanced.svg'
import icon6 from '../img/icons/integPageIcons/Icon_employee.svg'
import icon7 from '../img/icons/integPageIcons/Icon_invoicing.svg'
import icon8 from '../img/icons/integPageIcons/Icon_manual.svg'
import icon9 from '../img/icons/integPageIcons/Icon_dedicated.svg'
import icon10 from '../img/icons/integPageIcons/Icon_windows.svg'
import icon11 from '../img/icons/integPageIcons/Icon_mac.svg'
import icon12 from '../img/icons/integPageIcons/icon_Linux.svg'

import logoBottomAsana from '../img/Logo_download.svg'
import mobileLogo from '../img/logo_parts_animation/Logo_duefocus.svg'
import logo from '../img/icons/integrations/Logo_duefocus1.svg'

import coldStart from '../img/coldStartIntegrations/Asana.png'
import coldStartMini from '../img/coldStartIntegrations/Trello_mini.svg'


const header = 'Easy Time Tracking with DueFocus';
const description = 'Simplify working process of your favorite project management tool';
const buttonName = 'Import Your Team';


export default class AllIntegration extends React.Component {
  state = {
    hideAllFeatures: true
  }

  // showModal = () => {
  //   const modal = document.getElementById('modal-sign-up');
  //   modal.className ='show-modal';
  //   document.body.className = 'modal-active';
  // }

  handleShowMore = () => {
    this.setState({ hideAllFeatures: !this.state.hideAllFeatures });
    if (this.state.hideAllFeatures === true) {
      document.getElementById('height-fix').className += ' active'
      document.getElementById('hide-line').className += ' disable-integrations'
      document.getElementById('hide-line1').className += ' disable-integrations'
      document.getElementById('hide-line2').className += ' disable-integrations'
    } else {
      document.getElementById('height-fix').classList.remove('active')
      document.getElementById('hide-line').classList.remove('disable-integrations')
      document.getElementById('hide-line1').classList.remove('disable-integrations')
      document.getElementById('hide-line2').classList.remove('disable-integrations')
    }
  }

  //  handleCloseFeatures = () => {
  //   // document.getElementById('drop-down').classList.remove('drop-down-active');
  //   document.getElementById('overlay').removeAttribute('class');
  //   document.getElementById('drop-down-pricing').classList.remove('drop-down-active');
  //   document.body.removeAttribute('class');
  // }
  openSignUp = () => {
    return window.location.href = '/sign-up/';
  }
  render() {
    return (
      <>
        <EmptyBlock />
        {/* <div onMouseEnter={this.handleCloseFeatures} id='overlay'></div> */}
        <div className={styles.body}>
          <div id='height-fix' className={styles.main}>
            <img style={{ width: '60%', marginLeft: '70px' }} src={mobileLogo} width="auto" height="auto" alt="logo" />
            <div className={styles.left}>
              <h1>{header}</h1>
              <p>{description}</p>
              <div className={styles.buttons}>
                <button
                  onClick={this.openSignUp}
                  className={`${button.buttonSignUpBig} ${styles.visibleButton}`}
                >
                  {buttonName}
                </button>
              </div>
            </div>
            <div className={styles.right}>
              <img style={{ marginLeft: '185px' }} src={logo} width="auto" height="auto" alt="logo" />
            </div>
          </div>
          <div className={styles.integrationFixBlock}>
            <div className={styles.integration}>
              <div>
                <div>
                  <div><img src={icon4} width="auto" height="auto" alt='Time tracking' /><span>Time tracking</span></div>
                  <div><img src={icon1} width="auto" height="auto" alt='Activity levels' /><span>Activity levels</span></div>
                </div>
                <div>
                  <div><img src={icon2} width="auto" height="auto" alt='Screenshots' /><span>Screenshots</span></div>
                  <div><img src={icon3} width="auto" height="auto" alt='Online timesheets' /><span>Online timesheets</span></div>
                </div>
                <div>
                  <div><img src={icon5} width="auto" height="auto" alt='Advanced reporting' /><span>Advanced reporting</span></div>
                  <div><img src={icon6} width="auto" height="auto" alt='Employee payments' /><span>Employee payments</span></div>
                </div>
                <div id='hide-line'>
                  <div><img src={icon7} width="auto" height="auto" alt='Invoicing' /><span>Invoicing</span></div>
                  <div><img src={icon8} width="auto" height="auto" alt='Manual time' /><span>Manual time</span></div>
                </div>
                <div id='hide-line1'>
                  <div><img src={icon9} width="auto" height="auto" alt='Dedicated support' /><span>Dedicated support</span></div>
                  <div><img src={icon10} width="auto" height="auto" alt='Windows' /><span>Windows</span></div>
                </div>
                <div id='hide-line2'>
                  <div><img src={icon11} width="auto" height="auto" alt='Mac' /><span>Mac</span></div>
                  <div><img src={icon12} width="auto" height="auto" alt='Linux' /><span>Linux</span></div>
                </div>
              </div>
              <button
                onClick={this.handleShowMore}
                className={`${styles.showMore} ${button.learnMore}`}
              >
                {
                  (this.state.hideAllFeatures === true)
                    ? 'Show More'
                    : 'Show Less'
                }
              </button>
            </div>
          </div>
        </div>
        <AllIntegrationPage />
        <FourthBlock coldStart={coldStart} coldStartMini={coldStartMini} />
        {/* <FifthBlock /> */}
        <SixthBlock />
        <GetStarted logo={logoBottomAsana} />
      </>
    )
  }
}



