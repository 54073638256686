import React from 'react'
import styles from './coldStart.module.scss'
// import button from '../../buttons.module.scss'
import Accordion from '../../SeventhBlock/index'

import img1 from '../../img/icons/personal_integration/icon_MultivariateTracking.svg'
import img2 from '../../img/icons/personal_integration/icon_FocusedActivities.svg'
import img3 from '../../img/icons/personal_integration/icon_RealtimeTeamwork.svg'
import img4 from '../../img/icons/personal_integration/icon_EasyReporting.svg'
import img5 from '../../img/icons/personal_integration/Icon_FullIntegration.svg'
import img6 from '../../img/icons/personal_integration/icon_AutoTask.svg'
import background from '../../img/backgroung_integrations.svg'

import coldstart from '../../img/coldStartIntegrations/all.png';
import coldstartMini from '../../img/coldStartIntegrations/ClickUp_mini.svg';
export default class FourthBlock extends React.Component {
  render() {
    return (
      <>
        <div className={styles.main}>
          <div className={styles.background} />
          <img className={styles.backgroundImg} src={background} width="auto" height="auto" alt="background" />
          <div className={styles.mainContent}>
            <h4>Take Advantages of Powerful Feature Set</h4>
            <h5>Improve Your Productivity with <span>Cold Start</span></h5>
            <p>With DueFocus Cold Start feature begin tracking your projects time automatically or with just one click. What's more, now you can easily analyze and assign tracked time between different tasks particularly</p>
            <img className={styles.coldStartMobile} src={coldstartMini} width="auto" height="auto" alt="coldStartMini" />
            <img className={styles.coldStart} src={coldstart} width="auto" height="auto" alt="coldStart" />
          </div>
          <div className={styles.block}>
            <div>
              <div>
                <img src={img1} width="auto" height="auto" alt='icon' />
                <h5>Multivariate Tracking</h5>
                <p>Track time with one-click, manually or against tasks</p>
              </div>
              <div>
                <img src={img2} width="auto" height="auto" alt='icon' />
                {/* <h5>Focus Analysis</h5>
                <p>Measure productivity KPI's and cut out distractions</p> */}
                <h5>Embedded Task Tracker</h5>
                <p>Track work hours to your tasks and projects in one app</p>
              </div>
              <div className={styles.desktopVisible}>
                <img src={img3} width="auto" height="auto" alt='icon' />
                <h5>Real-time Monitoring</h5>
                <p>Be aware of how your team members spend working time</p>
              </div>
            </div>
            <div className={styles.mobileContent}>
              <div>
                <img src={img3} width="auto" height="auto" alt='icon' />
                <h5>Real-time Monitoring</h5>
                <p>Be aware of how your team members spend  working time</p>
              </div>
              <div>
                <img src={img4} width="auto" height="auto" alt='icon' />
                <h5>Easy Reporting</h5>
                <p>Send .xls reports to your task management tool, email, etc.</p>
              </div>
            </div>
            <div>
              <div className={styles.desktopVisible}>
                <img src={img4} width="auto" height="auto" alt='icon' />
                <h5>Easy Reporting</h5>
                <p>Send .xls reports to your task management tool, email, etc.</p>
              </div>
              <div>
                <img src={img5} width="auto" height="auto" alt='icon' />
                {/* <h5>Interaction with Integration</h5>
              <p>Direct, comprehensive, two-way API connection</p> */}
                <h5>Screenshots</h5>
                <p>Monitor performance & employees</p>
              </div>
              <div>
                <img src={img6} width="auto" height="auto" alt='icon' />
                <h5>Stage Mapping</h5>
                <p>Change status and priority of tasks during the time tracking</p>
              </div>
            </div>
            {/* <button className={button.buttonFeatures}>See All Features</button> */}
          </div>
        </div>
        <div className={styles.accordion}>
          <Accordion />
        </div>
      </>
    )
  }
}
