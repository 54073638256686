import React from 'react'
import styles from './index.module.scss'

import screen_1 from '../../img/screen_1.png'
import screen_2 from '../../img/screen_2.png'
import screen_3 from '../../img/screen_3.svg'

const text = "Sync DueFocus with your task tracking tool to establish a unified system of effective workflow. Once you've connected your project management software all your dashboards will be synchronized and available in the time tracker. Using DueFocus desktop application or Google Chrome Extension create tasks and report into it directly without switching between applications.";
const text2 = "Also DueFocus allows multivariate time tracking. Start tracking time and analyze your activity, or log time manually on a timeline or Reports page. By default, task will be automatically stopped when spent time exceeds planned time, subsequently you may edit it to continue tracking. Or as an alternative - use Auto-stop planned tasks feature to track time in Overspent mode, so task's time will continue recording despite the exceeding planned time.";

const text3 = 'Integration with DueFocus gives the answers to all the questions concerning working processes. Analyze and monitor all the teamwork activity with Team Pulse feature. Now you have an opportunity to watch how, what for and how much working time was spent. Furthermore, reports and comments to tasks provide a comprehensive insights into performance both individuals and a whole team. Get key information about workflow with DueFocus time tracking software';
const text4 = "DueFocus is not just about time tracking it is also about attention management. We analyze the ability to control your distraction, maximize your focus and engage your flow. Our main objective is not only to prove how much time you've spent but to show the efficiency of working processes you've involved in. Using DueFocus time tracking software also means thinking differently about how you plan your work: notice the order of tasks that works for you and adjust accordingly. Invest in your focus rather than inadvertently relinquish it.";
export default class FifthBlock extends React.Component {

  render() {

    return (
      <>
        <div className={styles.content}>

          <div className={styles.fstBlock}>
            <div className={styles.leftBlock}>
              <h2>Track Your Time in Different Ways</h2>
              <p>{text}</p>
              <p>{text2}</p>
            </div>
            <div className={styles.imgBlock}><img src={screen_1} width="auto" height="auto" alt='screen' /></div>
          </div>

          <div className={styles.fstBlock}>
            <div className={styles.imgBlock}><img src={screen_2} width="auto" height="auto" alt='screen' /></div>
            <div className={styles.rightBlock}>
              <h3>Manage Working Processes</h3>
              <p>{text3}</p>
            </div>
          </div>

          <div className={styles.fstBlock}>
            <div className={styles.leftBlock}>
              <h3>Stay Always Focused</h3>
              <p>{text4}</p>
            </div>
            <div className={styles.imgBlock3}><img src={screen_3} width="auto" height="auto" alt='screen' /></div>
          </div>
        </div>
      </>
    )
  }
}
