import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import AllIntegrations from '../components/PersonalIntegrations/allIntegrationPage'
import { Helmet } from "react-helmet";

// const content = `Progressive time management with DueFocus. Track time, invite colleagues, report time, make screenshots and create invoices in one place for free.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const AllIntegration = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Integrations for Tracking Time with Popular Task Trackers</title>
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/time-tracker-integrations/" />
    </Helmet>
    <Header />
    <AllIntegrations />
    <Footer />
  </>
)

export default AllIntegration